<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container :fluid="$screenSize < 600 ? true : false" v-else>
      <b-row>
        <b-col md="12">
          <div class="closeBtn mb-2 text-right">
            <b-button variant="danger" size="sm" @click="$router.push({ name: 'institute' })">Close</b-button>
          </div>
          <h4>{{ survey.name }} Choices</h4>
          <h6 class="text-danger" v-if="answerPostData.length == 0">
            Survey Questions not assigned yet, Please check back later!
          </h6>
          <b-form @submit.prevent="preparePostDataForSurveyAnswers()" v-else>
            <div class="choiceOptions">
              <div class="option" v-for="(course, courseIndex) in courseTeacherCombination" :key="courseIndex">
                <b-overlay :show="answerSurvey" spinner-variant="primary" spinner-type="grow" spinner-small
                  rounded="md">
                  <div class="courseTeacherCombination text-center">
                    <p class="text-white p-2 rounded bg-info font-weight-bolder">
                      Instructor Feedback Survey for
                      {{ course.course.name + "-" + course.teacher.first_name+" " + course.teacher.last_name }}
                    </p>
                  </div>
                  <div v-for="(choice, choiceIndex) in answerPostData" :key="choiceIndex">
                    <div class="border border-info rounded p-3 mb-3">
                      <div class="questionNoAndQuestion d-flex align-items-center">
                        <p style="font-size: 16px; font-weight: bolder">
                          {{ choice.question_no }}:
                        </p>
                        <p style="font-size: 16px; font-weight: 600" class="ml-2">
                          {{ choice.name }}
                        </p>
                      </div>
                      <div class="options">
                        <b-form-radio style="font-size: 14px; font-weight: 600"
                          v-for="(option, optionIndex) in choice.choices" :key="optionIndex" v-model="
                            surveyAnswers[course.course_id][course.teacher_id][
                              choice.survey_question_id
                            ].choice_id
                          " :value="option.id">{{ option.name }}</b-form-radio>
                      </div>

                      <div class="feedbackInputField"
                        v-if="choice.allow_descriptive == 1 || choice.choices.length == 0">
                        <b-form-input v-model="
                          surveyAnswers[course.course_id][course.teacher_id][
                            choice.survey_question_id
                          ].answer
                        " placeholder="Write Feedback....."></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
            <br />
            <div class="submitAnswers text-center">
              <b-button variant="primary" size="md" type="submit" :disabled="answerBtn.disabled">{{ answerBtn.text }}
              </b-button>
            </div>
          </b-form>
          <br /><br /><br /><br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  async created() {
    await this.getSurvey();
  },
  data() {
    return {
      fetchData: false,
      answerSurvey: false,
      survey: {},
      choices: [],
      selected: [],
      surveyData: [],
      answerPostData: [],
      courseTeacherCombination: [],
      answerBtn: {
        text: "Submit",
        disabled: false,
      },
      questionsAndChoices: [], //testing------------
      answers: {}, // testing---------------
      surveyAnswers: {},
    };
  },

  methods: {
    async prepareSurveyAnswersArray() {
      await this.courseTeacherCombination.forEach((course) => {
        if (!this.surveyAnswers[course.course_id])
          this.$set(this.surveyAnswers, course.course_id, {});

        if (!this.surveyAnswers[course.course_id][course.teacher_id])
          this.$set(this.surveyAnswers[course.course_id], course.teacher_id, {});

        this.questionsAndChoices.forEach((question) => {
          this.$set(
            this.surveyAnswers[course.course_id][course.teacher_id],
            question.id,
            {
              choice_id: "",
              answer: "",
            }
          );
        });
      });

      this.fetchData = await false;
    },

    //********* Prepare Post Data for Survey Answers **********/
    async preparePostDataForSurveyAnswers() {
      let status = null;
      await this.courseTeacherCombination.forEach((course) => {
        this.questionsAndChoices.forEach((question) => {
          if (
            question.answer_required &&
            this.surveyAnswers[course.course_id][course.teacher_id][question.id] &&
            this.surveyAnswers[course.course_id][course.teacher_id][question.id]
              .choice_id == "" &&
            question.choices.length != 0
          ) {
            status = true;
          }
          if (
            question.choices.length == 0 &&
            this.surveyAnswers[course.course_id][course.teacher_id][question.id] &&
            this.surveyAnswers[course.course_id][course.teacher_id][question.id].answer ==
            ""
          ) {
            status = true;
          }
        });
      });
      if (await status) {
        return await this.$toast.error("Please fill all the fields!", {
          position: "top",
          duration: 3000,
        });
      }

      this.answerBtn.text = "Please wait...";
      this.answerBtn.disabled = true;
      this.answerSurvey = true;
      let surveyAnswers = await {
        studfbsurveyanswers: [],
      };
      await this.courseTeacherCombination.forEach((course) => {
        this.answerPostData.forEach((question) => {
          surveyAnswers.studfbsurveyanswers.push({
            course_id: course.course_id,
            teacher_id: course.teacher_id,
            survey_question_id: question.survey_question_id,
            survey_choice_id: this.surveyAnswers[course.course_id][course.teacher_id][
              question.survey_question_id
            ].choice_id,
            answer: this.surveyAnswers[course.course_id][course.teacher_id][
              question.survey_question_id
            ].answer,
          });
        });
      });

      const submitSurveyAnswers = await this.submitSurveyAnswers(surveyAnswers);
      if (submitSurveyAnswers) {
        this.answerBtn.text = "Submitted";
        this.surveyAnswers = false;
        await this.$toast.success("Responses Submittd, Thank you :)", {
          position: "top",
          duration: 4000,
        });
        this.fetchData = await true;
        await this.$router.push({ name: "institute" });
      }
      if (!submitSurveyAnswers) {
        this.$toast.error("Something went wrong, Please try again!", {
          position: "top",
          duration: 3000,
        });
        this.clearResponse();
      }
      // }
    },
    //********* Submit Survey Answers with API **********/
    async submitSurveyAnswers(surveyAnswers) {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/studfbsurveyanswer`;
      let status = null;
      await this.$axios
        .post(url, surveyAnswers)
        .then((response) => {
          response;
          status = true;
          response;
        })
        .catch((error) => {
          error;
          status = true;
          // error;
        });
      return status;
    },
    //********* Getting Survey Details **********/
    async getSurvey() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          this.startSurvey();

          response;
        })
        .catch((error) => {
          error;
        });
    },
    //********* Start Survey **********/
    async startSurvey() {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${this.survey.id}/start`;
      await this.$axios
        .get(url)
        .then((response) => {
          response;
          this.answerPostData = [];
          this.courseTeacherCombination = [];

          this.surveyData = response.data.questionsChoices;
          this.courseTeacherCombination = response.data.surveyCourceTeachers;
          this.questionsAndChoices = response.data.questionsChoices; // testing-------

          // this.courseTeacherCombination.forEach((teacher) => {
          this.surveyData.forEach((question) => {
            this.answerPostData.push({
              // course_id: teacher.course_id,
              // teacher_id: teacher.teacher_id,
              survey_question_id: question.id,
              survey_choice_id: "",
              answer: "",
              question_no: question.question_no,
              name: question.name,
              allow_descriptive: question.allow_descriptive,
              answer_required: question.answer_required,
              choices: question.choices,
            });
          });
          response;
          this.prepareSurveyAnswersArray();
          // this.fetchData = false;
        })
        .catch((error) => {
          error;
          error;
        });
    },
    clearResponse() {
      this.answerBtn.text = "Submit";
      this.surveyAnswers = false;
      setTimeout(() => {
        this.answerBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
